import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import Config from '../config/config';

function App() {
  const [response, setResponse] = useState("");

  useEffect(() => {
    const socketserver = `${Config.websocketServer}:${Config.websocketPort}/hilt/mud`;
    console.log('CONNECTING TO: ' + socketserver);



    const socket = new WebSocket(socketserver);

    let timeout;
    const ping = () => {
      console.log('PINGING!');
      socket.send('__ping__');
      timeout = setTimeout(() => {
        socket.close();
      }, 5000);
    };

    const pong = () => {
      console.log('PONGING!');
      clearTimeout(timeout);
    }

    socket.onopen = (event) => {
      console.log(`OPEN: ${JSON.stringify(event)}`);
      setInterval(ping, 30000);
    };

    socket.onmessage = (event) => {
      console.log(`MESSAGE: ${JSON.stringify(event.data)}`);
      if (event.data === '__pong__') {
        pong();
        return;
      }
    }

    socket.onclose = (event) => {
      console.log(`CLOSE: ${JSON.stringify(event)}`);
    }

    socket.onerror = (event) => {
      console.log(`ERROR: ${JSON.stringify(event)}`);
    }


  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload. This is a change.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
