import dev from "./dev-config";
import test from "./test-config";

export interface Configuration {
    websocketServer: string;
    websocketPort: number;
}

let Config: Configuration = {
    websocketServer: '',
    websocketPort: 0
};
const env = process.env.REACT_APP_STAGE;
if (env === 'local') {
    Config = dev; // Local development
} 
else if (env === 'test') {
    Config = test; // Test server
} 
// else if (env === 'prod') {
//     config = prod; // Production serverr
// }

export default Config;